import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components'

const Outer = styled.li`
  > div {
    pointer-events: ${props => (props.visibility ? 'initial' : 'none')};
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
  }
`

const ToggleIcon = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  float: right;
  padding: 8px 0 2px;
  margin-right: -6px;
`

const Accordion = ({ title, innerHTML, toggleSVG, ...props }) => {
  const [visibility, setVisibility] = useState(false)
  return (
    <Outer
      toggleSVG={toggleSVG}
      visibility={visibility}
      className={visibility ? 'is-expanded' : null}
      onClick={() => {
        setVisibility(!visibility)
      }}
    >
      <h4>
        {toggleSVG ? <ToggleIcon>{toggleSVG}</ToggleIcon> : null}
        {title}
      </h4>
      <div
        dangerouslySetInnerHTML={{ __html: innerHTML }}
        onClick={e => e.stopPropagation()}
      />
    </Outer>
  )
}

Accordion.propTypes = {
  innerHTML: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default Accordion

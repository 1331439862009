import { graphql, Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import Accordion from '../components/Accordion'
import Container from '../components/Container'
import InPageNav from '../components/InPageNav'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import Section from '../components/Section'
import SectionTitle from '../components/SectionTitle'
import SEO from '../components/SEO'
import ArrowUp from '../images/arrow--caret-up.svg'

const AfterParallaxWrap = styled.div`
  position: relative;
  z-index: 2;
  /* background-color: ${props => props.theme.colors.nearlyWhite}; */
`

const QandAType = styled.div`
  > h3 {
    text-align: left;
  }
  + div {
    > h3 {
      margin-top: 40px;
    }
  }
`

const QandAListContainer = styled.ul`
  max-width: 740px;
  margin-left: auto;
  margin-right: auto;

  > li {
    padding: 15px 20px;
    background-color: white;
    cursor: pointer;
    border: 1px solid ${props => props.theme.colors.lightBorder};

    + li {
      border-top: none;
    }

    > div {
      height: 0;
      opacity: 0;
      transform: translate3d(0, -40px, 0) scale(0.98);
      transition: 0ms;
      cursor: initial;
    }

    h4 {
      position: relative;
      z-index: 1;
      margin-bottom: 0;
      font-family: ${props => props.theme.typography.family};
      font-size: 17px;
      line-height: 22px;
      font-weight: 600;
      background-color: white;

      > span svg {
        transform: rotate(180deg);
        transition: transform 120ms ease-out;
      }
    }

    p {
      font-size: 14px;
      line-height: 22px;
    }

    &.is-expanded {
      h4 {
        margin-bottom: 15px;

        > span svg {
          transform: rotate(0);
        }
      }
      > div {
        height: auto;
        transform: translate3d(0, 0, 0) scale(1);
        opacity: 1;
        transition: opacity 200ms ease-in, transform 120ms ease-out;
      }
    }
  }
`

const Support = ({ data }) => {
  const qandAs = data.allContentfulQAndA.edges

  const sortedQandAs = qandAs => {
    const sortedQandAs = {}

    for (const qandA of qandAs) {
      const key = `${qandA.node.type.replace(' ', '_')}`
      if (!sortedQandAs[key]) {
        sortedQandAs[key] = []
      }
      sortedQandAs[key].push(qandA)
    }
    const qandAByType = []
    for (const qandAs of Object.values(sortedQandAs)) {
      const qandAObj = {
        questionType: qandAs[0].node.type,
        qandAs,
      }
      qandAByType.push(qandAObj)
    }
    return qandAByType
  }

  const qandAByType = sortedQandAs(qandAs)

  return (
    <Layout>
      <SEO />

      <PageHeader
        image={data.allContentfulAsset.edges[0].node}
        title="Support"
      />
      <AfterParallaxWrap id="stickyNavContainer">
        <InPageNav id="stickyNav">
          <Link to="/support/" activeClassName="active-page-link">
            FAQs
          </Link>
          <Link to="/support/customers/" activeClassName="active-page-link">
            Customers
          </Link>
        </InPageNav>
        <Container wrap="true" afterStickyNav="true">
          <Section>
            {qandAByType.map((qandAType, index) => (
              <QandAType key={`${qandAType.qandAs[0].id$}${index}`}>
                <SectionTitle title={qandAType.questionType} />
                <QandAListContainer>
                  {qandAType.qandAs.map(({ node: qandA }) => (
                    <Accordion
                      key={qandA.id}
                      title={qandA.title}
                      toggleSVG={<ArrowUp width="14px" />}
                      innerHTML={qandA.body.childMarkdownRemark.html}
                    />
                  ))}
                </QandAListContainer>
              </QandAType>
            ))}
          </Section>
        </Container>
      </AfterParallaxWrap>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulQAndA(sort: { fields: [type], order: [DESC] }) {
      edges {
        node {
          createdAt
          id
          body {
            childMarkdownRemark {
              html
            }
          }
          title
          type
        }
      }
    }
    allContentfulAsset(filter: { title: { eq: "news-hero-image" } }) {
      edges {
        node {
          ...HeroImageSettings
        }
      }
    }
  }
`

export default Support
